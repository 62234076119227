import * as React from 'react'
import hooks from '../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { MessageBar, MessageBarType, PrimaryButton, Stack, StackItem, TextField } from '@fluentui/react'
import { LoginBody } from '../components/wrappers'
import { SmallGap } from '../utils/globals'
import { ErrorContext } from '../components/wrappers';


const Login: React.FC = () => {
    let navigate = useNavigate()
    const { showErrorMessage } = React.useContext(ErrorContext)
    const [email, setEmail] = React.useState<string>()
    const [invalid, setInvalid] = React.useState(false)

    const handleSubmissionEvent = async () => {
        if (!email || email.length === 0 || !email.includes('@')) setInvalid(true)
        if (!email) return
        const result = await hooks.startLogin({ email })
        if (result) {
            navigate('/confirmlogin/' + result.loginChallangeId)
        }else {
            showErrorMessage("Der skete en fejl")
        }
    }

    React.useEffect(() => {
        (async () => {
            const result = await hooks.getUser()
            if (result) navigate('/')

        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LoginBody>
            <Stack tokens={{ childrenGap: SmallGap }}>
                <StackItem><h1>Login</h1></StackItem>

                <StackItem><span>Email</span></StackItem>
                <StackItem><TextField onChange={(_e, val) => { setEmail(val); setInvalid(false) }} /></StackItem>

                <StackItem> <PrimaryButton text="Næste" onClick={handleSubmissionEvent} allowDisabledFocus disabled={invalid || !email} /></StackItem>
                {invalid && <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setInvalid(false)}>Email is invalid</MessageBar>}

            </Stack>
        </LoginBody>
    )
}

export default Login