import * as React from 'react'
import { PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react'
import { RootBody } from '../components/wrappers'
import AdminHooks from '../utils/AdminHooks'
import { useParams } from 'react-router-dom'
import { LargeGap } from '../utils/globals'


const FilePage: React.FC = () => {
    const { fileId } = useParams<{ fileId: string }>()
    const [fileLink, setFileLink] = React.useState<string | undefined>()


    const getData = async (id: string) => {
        setFileLink(undefined)
        const result = await AdminHooks.getFileLink(id)
        if (result) {
            setFileLink(result)
        }
    }

    React.useEffect(() => {
        if (fileId) getData(fileId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <RootBody>
            <Stack verticalAlign='center' horizontalAlign='center' style={{ minHeight: 400 }} tokens={{ childrenGap: LargeGap }}>
                {!fileLink && <Spinner size={SpinnerSize.large} />}
                {fileLink && (<>
                    <Stack.Item><h3>Filen er klar</h3></Stack.Item>
                    <Stack.Item><a href={fileLink}><PrimaryButton>Download filen her</PrimaryButton></a></Stack.Item>
                    <Stack.Item><img alt={fileLink} src={fileLink} /></Stack.Item>
                </>)}

            </Stack>
        </RootBody>
    )

}

export default FilePage