import { Investment, Investor } from '@fbam/common'
import * as React from 'react'
import { Checkbox, CheckboxVisibility, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Panel, PanelType, PrimaryButton, ShimmeredDetailsList, Spinner, SpinnerSize, Stack } from '@fluentui/react'
import { ContractTemplates, LargeGap, SmallGap } from '../utils/globals'
import { RootBody } from '../components/wrappers'
import AdminHooks from '../utils/AdminHooks'
import { InvestementTableFormatter } from './Dashboard'
import { useSearchParams } from 'react-router-dom'
import { ApprovedIcon } from '../components/Icons'
import { DateStringToNiceDate } from '../utils/formatters'

const CreateContractView: React.FC<{investment: Investment, setInvestment(update: Investment): void}> = ({ investment, setInvestment }) => {
    const [loading, setLoading] = React.useState(false)
    const [selectedContract, setSelectedContract] = React.useState<string | undefined>();
    const [sendPrEmail, setSendPrEmail] = React.useState(true)

    const handleCreateContract = async () => {
        if(!selectedContract) return
        setLoading(true)
        await AdminHooks.createContract(selectedContract, investment.investmentId)
        setLoading(false)
    }

    return (
        <Stack tokens={{childrenGap: SmallGap}}>
            <Stack.Item>
                <Dropdown
                    placeholder="Vælge skabelon"
                    label="Opret ny kontrakt"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(_event, item) => {
                        const formatted = item?.key.toString()
                        setSelectedContract(formatted)
                    }}
                    options={ContractTemplates.map((contract) => ({ key: contract.templateId, text: contract.name }))}
                />
            </Stack.Item>
            <Stack horizontal>
                <Checkbox label="Send kontrakt pr email" checked={sendPrEmail} onChange={()=>setSendPrEmail(!sendPrEmail)} />
                <PrimaryButton disabled={!selectedContract}>Opret kontrakt</PrimaryButton>
            </Stack>
        </Stack>

    )
}

const ContractsViews: React.FC<{ investment: Investment }> = ({ investment }) => {
    const [open, setOpen] = React.useState(false)
    const [localInvestment, setLocalInvestment] = React.useState(investment)

    const enterView = () => {
        setOpen(true)
    }

    const exitView = () => {
        setOpen(false)
    }

    return (
        <>
            <DefaultButton text="Se mere" onClick={enterView} />
            {open && (
                <Dialog hidden={!open} onDismiss={exitView}
                    dialogContentProps={{ type: DialogType.normal, title: 'Kontrakter for denne investering', closeButtonAriaLabel: 'Close' }}>
                    <ShimmeredDetailsList enableShimmer={!localInvestment}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        columns={[
                            { key: "url", name: "Link", minWidth: 100, fieldName: "url" },
                        ]}
                        items={(localInvestment.contracts || []).map((item) => ({ url: item.url, signatureDate: item.signatureDate ? DateStringToNiceDate(item.signatureDate) : "Ikke underskrevet" }))} />
                    <CreateContractView investment={localInvestment} setInvestment={setLocalInvestment} />
                    <DialogFooter>
                        <DefaultButton onClick={exitView} text="Luk" />
                    </DialogFooter>
                </Dialog>
            )}
        </>
    )
}

const StackItemInvestorPanel: React.FC<React.PropsWithChildren> = ({ children }) => <Stack.Item><span>{children}</span></Stack.Item>


export const AdminInvestementTableFormatter = (investment: Investment) => {
    return { ...InvestementTableFormatter(investment), contracts: <ContractsViews investment={investment} /> }
}

const NAItems = "N/A"
const AdminInvestorPanel: React.FC<{ initialInvestor: Investor, onExit: () => void, preselected: boolean }> = ({ initialInvestor, onExit, preselected }) => {
    const [investor, setInvestor] = React.useState(initialInvestor)
    const [isOpen, setIsOpen] = React.useState<boolean>(!!preselected)
    const [loadingApproval, setLoadingApproval] = React.useState(false)
    const [investments, setInvestments] = React.useState<Investment[]>()
    const [searchParams, setSearchParams] = useSearchParams();


    const handleApprove = async () => {
        setLoadingApproval(true)
        const result = await AdminHooks.confirmInvestorData(investor.userId)
        if (result) setInvestor(result)
        setLoadingApproval(false)
    }

    React.useEffect(() => {
        if (isOpen) {
            (async () => {
                const result = await AdminHooks.getInvestoDashboard(investor.userId)
                if (result) setInvestments(result.investments)
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const exitView = () => {
        setIsOpen(false)
        onExit()
    }

    const enterView = () => {
        if (!searchParams.has("userId")) {
            searchParams.set("userId", initialInvestor.userId);
            setSearchParams(searchParams);
        }
        setIsOpen(true)
    }

    return (
        <>
            <DefaultButton text="Se mere" onClick={enterView} />
            <Panel isOpen={isOpen} onDismiss={exitView} type={PanelType.large} closeButtonAriaLabel="Close" headerText={investor.name}>
                <Stack style={{ marginTop: SmallGap }} tokens={{ childrenGap: SmallGap }}>
                    <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                        <ApprovedIcon approved={investor.approved} />
                        <span>{investor.userId}</span>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: LargeGap }}>
                        <Stack tokens={{ childrenGap: SmallGap }}>
                            <Stack.Item><h3>Investor informaiton</h3></Stack.Item>
                            <StackItemInvestorPanel>Email: <b>{investor.email}</b></StackItemInvestorPanel>
                            <StackItemInvestorPanel>Phone: <b>{investor.phone}</b></StackItemInvestorPanel>
                            <StackItemInvestorPanel>CPR/CVR: <b>{investor.identitficationReference}</b></StackItemInvestorPanel>
                            <StackItemInvestorPanel>Passport: <a target="_blank" rel="noreferrer" href={"/files/" + investor.passportFileKey}>Åben fil</a></StackItemInvestorPanel>
                            <StackItemInvestorPanel>Address id: <a target="_blank" rel="noreferrer" href={"/files/" + investor.addressDocumentationFileKey}>Åben fil</a></StackItemInvestorPanel>
                        </Stack>
                        <Stack tokens={{ childrenGap: SmallGap }}>
                            <Stack.Item><h3>KYC Data</h3></Stack.Item>
                            <StackItemInvestorPanel>Invested money origin: <b>{investor.fundsSource || NAItems}</b></StackItemInvestorPanel>
                            <StackItemInvestorPanel>Investment reason: <b>{investor.fundingReason || NAItems}</b></StackItemInvestorPanel>
                            <StackItemInvestorPanel>Person on pep list: <b>{investor.peplist === undefined ? NAItems : investor.peplist ? "Yes" : "No"}</b></StackItemInvestorPanel>
                            <StackItemInvestorPanel>Target investment sum: <b>{investor.fundingTargetPlan || NAItems}</b></StackItemInvestorPanel>
                        </Stack>
                    </Stack>

                    <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                        <Stack.Item><DefaultButton onClick={exitView}>Luk vindue</DefaultButton></Stack.Item>
                        <Stack.Item><PrimaryButton onClick={handleApprove}>{loadingApproval ? <Spinner size={SpinnerSize.small} /> : "Godkend investor"}</PrimaryButton></Stack.Item>
                    </Stack>
                    <Stack.Item><h3>Investeringer</h3></Stack.Item>
                    <ShimmeredDetailsList
                        enableShimmer={!investments}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        columns={[
                            { key: "project", name: "Projekt", minWidth: 100, fieldName: "projectTitle" },
                            { key: "amount", name: "Beløb", minWidth: 100, fieldName: "amount" },
                            { key: "amount", name: "Rente", minWidth: 100, fieldName: "interestRate" },
                            { key: "date", name: "Oprettet", minWidth: 130, fieldName: "createdAt" },
                            { key: "contracts", name: "Kontrakter", minWidth: 130, fieldName: "contracts" },
                        ]}
                        items={(investments || []).map(AdminInvestementTableFormatter)} />
                </Stack>
            </Panel>

        </>
    )
}

const AdminInvestorTableFormatter = (onExit: () => void, preselectedInvestorId?: string) => (investor: Investor) => {
    return {
        investorEmail: investor.email,
        investorName: investor.name,
        investorPhone: investor.phone,
        investorAddress: investor.address,
        investorIdentitficationReference: investor.identitficationReference,
        investorStatus: <ApprovedIcon approved={investor.approved} />,
        pendingApproval: investor.pendingAprroval ? <ApprovedIcon approved={false} customFalseText='Afventer' /> : "",
        investorPassport: <AdminInvestorPanel initialInvestor={investor} onExit={onExit} preselected={preselectedInvestorId === investor.userId} />,
    }
}


const AdminPage: React.FC = () => {
    const [investor, setInvestor] = React.useState<Investor[] | undefined>()
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId") || undefined


    const getData = async () => {
        setInvestor(undefined)
        const result = await AdminHooks.listInvestor()
        if (result) {
            setInvestor(result)
        }
    }

    const onExitInvestorDetails = async () => {
        if (searchParams.has("userId")) {
            searchParams.delete("userId");
            setSearchParams(searchParams);
        }
        await getData()
    }

    React.useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <RootBody>
            <Stack style={{ padding: SmallGap }}>
                <Stack horizontal tokens={{ childrenGap: LargeGap }}>
                    <Stack style={{ width: '100%' }}>
                        <Stack.Item><h2>Alle investor</h2></Stack.Item>
                        <ShimmeredDetailsList
                            enableShimmer={!investor}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            columns={[
                                { key: "email", name: "Email", minWidth: 120, fieldName: "investorEmail" },
                                { key: "name", name: "Name", minWidth: 120, fieldName: "investorName" },
                                { key: "phone", name: "Phone", minWidth: 80, fieldName: "investorPhone" },
                                { key: "address", name: "Address", minWidth: 120, fieldName: "investorAddress" },
                                { key: "identificationReference", name: "CPR/SSN", minWidth: 80, fieldName: "investorIdentitficationReference" },
                                { key: "status", name: "Status", minWidth: 100, fieldName: "investorStatus" },
                                { key: "pendingApproval", name: "Godkendelse", minWidth: 80, fieldName: "pendingApproval" },
                                { key: "passport", name: "Se mere", minWidth: 80, fieldName: "investorPassport" }
                            ]}
                            items={(investor || []).map(AdminInvestorTableFormatter(onExitInvestorDetails, userId))} />
                    </Stack>
                </Stack>
            </Stack>
        </RootBody>
    )

}

export default AdminPage