import { CreateInvestmentContractProps, DashboardData, Investment, Investor } from '@fbam/common';
import axios from 'axios';
import { endpoint, wrapper } from './hooks';


const listInvestor = async (): Promise<Investor[] | undefined> => await wrapper(async () => {
    const result = await axios.get<Investor[]>(endpoint + 'admin/list' , { withCredentials: true })
    return result.data
})

const confirmInvestorData = async (userId: string): Promise<Investor | undefined> => await wrapper(async () => {
    const result = await axios.post<Investor>(endpoint + 'admin/confirm/'+userId , {}, { withCredentials: true })
    return result.data
})

const getInvestoDashboard = async (userId: string): Promise<DashboardData | undefined> => await wrapper(async () => {
    const result = await axios.get<DashboardData>(endpoint + 'admin/dashboard/'+userId, { withCredentials: true })
    return result.data
})

const getFileLink = async (fileId: string): Promise<string | undefined> => await wrapper(async () => {
    const result = await axios.get<string>(endpoint + 'admin/file/'+fileId, { withCredentials: true })
    return result.data
})

const createContract = async (contractTemplateId: string, investmentId: string): Promise<Investment | undefined> => await wrapper(async () => {
    const props: CreateInvestmentContractProps = {investmentId, contractTemplateId}
    const result = await axios.post<Investment>(endpoint + 'admin/investment/contract', props, { withCredentials: true })
    return result.data
})

const AdminHooks = { listInvestor, confirmInvestorData, getInvestoDashboard, getFileLink, createContract }

export default AdminHooks