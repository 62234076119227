import { DashboardData, Investment, Investor } from '@fbam/common'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import hooks from '../utils/hooks'
import { CheckboxVisibility, Link, SharedColors, ShimmeredDetailsList, Stack } from '@fluentui/react'
import { Colors, LargeGap, SmallGap } from '../utils/globals'
import { RootBody } from '../components/wrappers'
import { DateStringToNiceDate, NiceIntrestRate, NiceNumberWithCurrency } from '../utils/formatters'
import NavBar, { Footer } from '../components/NavComponents'
import EditInvestor from '../components/EditInvestor'
import { FontIcon } from '@fluentui/react/lib/Icon';
import { IsMobile, useWindowSize } from '../utils/UIHooks'


const getProcessStepFromDashboardData = (dashboardData: DashboardData) => {
    if (dashboardData.investments.find((i) => i.paymentDate)) return 5 //Payment has been made
    const hasContracts = dashboardData.investments.filter((i) => !!i.contracts).length > 0
    if (hasContracts && dashboardData.investments.find((i) => i.contracts && !!i.contracts.find((si) => si.signatureDate))) return 4 //Contract signed
    if(hasContracts) return 3 //Has recived one contract
    
    if(dashboardData.investor.approved) return 2
    else return 1
}

enum GetStartedItemStatus {
    completed = "completed", inProgress = "inProgress", incomplete = "incomplete"
}

const getStepStatus = (current: number, step: number) => current > step ? GetStartedItemStatus.completed : current === step ? GetStartedItemStatus.inProgress : GetStartedItemStatus.incomplete

const GetStartedItem: React.FC<{ title: string, subTitle: string, status: GetStartedItemStatus }> = ({ title, subTitle, status }) => {
    const mobile = IsMobile(useWindowSize().width)

    let iconName = "Cancel"
    if (status === GetStartedItemStatus.completed) iconName = "CheckMark"
    if (status === GetStartedItemStatus.inProgress) iconName = "Sync"

    return (
        <Stack.Item style={{ maxWidth: mobile?130:200, background: Colors.light, padding: SmallGap }}>
            {status !== GetStartedItemStatus.incomplete && <FontIcon iconName={iconName} style={{ paddingRight: SmallGap, color: status === GetStartedItemStatus.completed ? SharedColors.green10 : Colors.black }} />}
            <span style={{ color: status === GetStartedItemStatus.inProgress ? Colors.black : Colors.gray, fontWeight: 500 }}>{title}</span>
            <p style={{color: status === GetStartedItemStatus.inProgress ? Colors.black : Colors.gray}}>{subTitle}</p>
        </Stack.Item>
    )
}

export const InvestementTableFormatter = (investment: Investment) => {
    return {
        projectTitle: investment.projectTitle,
        amount: NiceNumberWithCurrency(investment.amount),
        interestRate: NiceIntrestRate(investment.interestRate),
        createdAt: DateStringToNiceDate(investment.createdAt)
    }
}

const Dashboard: React.FC = () => {
    const navigate = useNavigate()
    const [investor, setInvestor] = React.useState<Investor>()
    const [investments, setInvestment] = React.useState<Investment[]>()
    const [onboardingStep, setOnboardingStep] = React.useState(0)

    React.useEffect(() => {
        (async () => {
            try {
                const result = await hooks.getDahboardData()
                if (result) {
                    setInvestor(result.investor)
                    setInvestment(result.investments)

                    setOnboardingStep(getProcessStepFromDashboardData(result))

                }
                else navigate('/login')

            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <RootBody>
            <Stack style={{ padding: SmallGap }}>
                <NavBar />
                <Stack horizontal tokens={{ childrenGap: LargeGap }}>
                    <EditInvestor investor={investor} updateInvestor={setInvestor} />
                    <Stack>
                        <Stack.Item><h2>Dine investeringer</h2></Stack.Item>
                        <ShimmeredDetailsList
                            enableShimmer={!investments}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            columns={[
                                { key: "project", name: "Projekt", minWidth: 100, fieldName: "projectTitle" },
                                { key: "amount", name: "Beløb", minWidth: 100, fieldName: "amount" },
                                { key: "amount", name: "Rente", minWidth: 100, fieldName: "interestRate" },
                                { key: "date", name: "Oprettet", minWidth: 130, fieldName: "createdAt" }]}
                            items={(investments || []).map(InvestementTableFormatter)} />

                        {investments && investments.length === 0 && (
                            <Stack.Item align='center' style={{ textAlign: 'center' }}>
                                <h3>Du har ingen investeringer endnu</h3>
                                <Link target='_blank' href='https://www.fbam.dk/fonde'>Kom igang her</Link>
                            </Stack.Item>
                        )}
                        <Stack.Item>
                            <h2>Sådan fungere det</h2>
                            <Stack horizontal wrap tokens={{ childrenGap: SmallGap }}>
                                <GetStartedItem title="1. Beløb og oplysninger" subTitle='Indtast beløb og grundlæggende oplysninger.' status={getStepStatus(onboardingStep, 0)} />
                                <GetStartedItem title="2: Email og KYC oplysninger" subTitle='Modtag e-mailbekræftelse og udfyld KYC oplysninger. Afvent derefter godkendelse fra vores KYC-Team.' status={getStepStatus(onboardingStep, 1)} />
                                <GetStartedItem title="3. Godkendelse og kontrakt" subTitle='Efter godkendelsen vil vi tilsende dig en kontrakt.' status={getStepStatus(onboardingStep, 2)} />
                                <GetStartedItem title="4. Kontrakt og gennemførsel" subTitle='Underskriv kontrakten og afvent gennemførsel af investeringsrunden.' status={getStepStatus(onboardingStep, 3)} />
                                <GetStartedItem title="5. Overfør penge" subTitle='Overfør beløbet for at fuldføre investeringen.' status={getStepStatus(onboardingStep, 4)} />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
            <Footer/>

        </RootBody>
    )

}

export default Dashboard