import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import hooks from '../utils/hooks'
import { LoginBody } from '../components/wrappers'
import { MessageBar, MessageBarType, PrimaryButton, Stack, StackItem, TextField } from '@fluentui/react'
import { SmallGap } from '../utils/globals'


const ConfirmLogin: React.FC = () => {
    const { sessionId } = useParams()
    const navigate = useNavigate()
    const [passCode, setPasscode] = React.useState<string>()
    const [invalid, setInvalid] = React.useState(false)
    const [invalidFromServer, setInvalidFromServer] = React.useState(false)

    const handleSubmissionEvent = async () => {
        if (!passCode || passCode.length !== 6) setInvalid(true)
        if (!passCode || !sessionId) return undefined
        const result = await hooks.confirmUser({
            challangeInput: passCode.split(' ').join(''),
            loginChallangeId: sessionId
        })
        if(result?.sucess) navigate('/')
        else setInvalidFromServer(true)
    }

    React.useEffect(() => {
        (async () => {
            try {
                const result = await hooks.getUser()
                if(result) navigate('/')
            } catch (error) {
                
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LoginBody>
            <Stack tokens={{ childrenGap: SmallGap }}>
                <StackItem><h1>Indtast kode</h1></StackItem>
                <StackItem><p>Vi har tilsendt en engangskode på din email</p></StackItem>

                <StackItem><span>Engangskode </span></StackItem>
                <StackItem><TextField onChange={(_e, val) => { setPasscode(val); setInvalid(false) }} /></StackItem>

                <StackItem> <PrimaryButton text="Gennemfør login" onClick={handleSubmissionEvent} allowDisabledFocus disabled={invalid || !passCode} /></StackItem>
                {invalid && <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setInvalid(false)}>Engangskoden er invalid</MessageBar>}
                {invalidFromServer && <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setInvalid(false)}>Engangskoden er ikke korrekt</MessageBar>}

            </Stack>
        </LoginBody>
    )
}

export default ConfirmLogin