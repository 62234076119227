
const stringToStringArray = (term: string): string[] => {
    const arr: string[] = []
    for (let index = 0; index < term.length; index++) {
        const element = term[index];
        arr.push(element)
    }
    return arr
}

export const NiceNumber = (input: number): string => {
    const number = input.toFixed(0)
    const numberAsString = (number.toString())
    const numberAsArray = stringToStringArray(numberAsString).reverse()
    let formattedOutput = ""
    for (let index = 0; index < numberAsArray.length; index++) {
        const element = numberAsArray[index];
        if (index > 0 && index % 3 === 0) formattedOutput = formattedOutput + '.'
        formattedOutput = formattedOutput + element
    }

    const formattedOutputArray = stringToStringArray(formattedOutput).reverse()

    return formattedOutputArray.join('')
}

export const NiceNumberWithCurrency = (number: number): string => {
    return NiceNumber(number) + " DKK"
}

const intrest_suffix = " %"
export const NiceIntrestRate = (number: number, noDecimals?: boolean): string => {
    if (noDecimals) return Math.round(number * 100) + intrest_suffix
    return ((number * 100).toFixed(0)) + intrest_suffix
}

export const getDateString = (): string => {
    const date = new Date()
    return date.toUTCString()
}


export const DateStringToNiceDate = (dateString: string): string => {
    const date = new Date(dateString)
    const monthTmp = (date.getMonth() + 1) + ""
    const month = monthTmp.length === 2 ? monthTmp : "0" + monthTmp
    const dayOfMonth = date.getDate().toString().length === 2 ? date.getDate().toString() : "0" + date.getDate().toString()
    return dayOfMonth + '/' + month + '/' + date.getFullYear()
}
