import { Investor, UpdateableInvestorProperties } from '@fbam/common'
import * as React from 'react'
import { ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, Panel, PanelType, PrimaryButton, SharedColors, Shimmer, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react'
import { LargeGap, SmallGap } from '../utils/globals'
import { FontIcon } from '@fluentui/react/lib/Icon';
import hooks from '../utils/hooks';
import { ErrorContext } from './wrappers';
import { ApprovedIcon } from './Icons';

  
const KYCDialog: React.FC<{ investor?: Investor, updateInvestor: (updated: Investor) => void, loading: boolean, openKYC: boolean, setOpenKYC(update: boolean):void }> = ({ investor, updateInvestor, openKYC, setOpenKYC }) => {
    const { showErrorMessage } = React.useContext(ErrorContext)
    const [loading, setLoading] = React.useState(false);

    const [isDialogVisible, setIsDialogVisible] = React.useState(false);
    const [fundsSource, setFundsSource] = React.useState(investor?.fundsSource);
    const [fundingReason, setFundingReason] = React.useState(investor?.fundingReason);
    const [peplist, setPeplist] = React.useState(investor?.peplist);
    const [fundingTargetPlan, setFundingTargetPlan] = React.useState(investor?.fundingTargetPlan);


    const disableNext = !fundingReason || !fundsSource || !fundingTargetPlan || peplist === undefined

    const restFields = () => {
        setFundsSource(investor?.fundsSource)
        setFundingReason(investor?.fundingReason)
        setPeplist(investor?.peplist)
        setFundingTargetPlan(investor?.fundingTargetPlan)
    }

    const onComplete = async () => {
        setLoading(true)
        const result = await hooks.updateInvestor({fundsSource, peplist, fundingReason, fundingTargetPlan})
        if(result) updateInvestor(result)
        else showErrorMessage("Kunne ikke opodatere: "+ JSON.stringify({fundsSource, peplist, fundingReason, fundingTargetPlan}))
        restFields()
        setOpenKYC(false)
        setLoading(false)
    }

    const onCancel = () => {
        restFields()
        setIsDialogVisible(true)
    }

    return (
        <Stack>

            <Panel
                isOpen={openKYC}
                type={PanelType.medium}
                hasCloseButton={false}
                headerText='Opdatering af nødvendige KYC-oplysninger'
                onRenderFooterContent={() => (
                    <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                        <PrimaryButton disabled={disableNext || loading} onClick={onComplete} text="Gem" />
                        <DefaultButton disabled={loading} onClick={onCancel} text="Annuller" />
                        {loading && <Spinner size={SpinnerSize.medium} />}
                    </Stack>
                )}
                onDismiss={() => onCancel()}>
                <Stack tokens={{ childrenGap: LargeGap }}>
                    <Stack.Item>
                        <p>For at styrke sikkerheden og overholde gældende lovkrav, beder vi venligst om, at du opdaterer dine Know Your Customer (KYC) oplysninger. Dette vil hjælpe os med at bevare integriteten af din investering og sikre en fortsat problemfri service.</p>
                    </Stack.Item>
                    <Stack.Item>
                        <ChoiceGroup label='Hvorfra kommer kapitalen du vil investere?'
                            selectedKey={fundsSource}
                            onChange={(_event, option) => setFundsSource(option?.key || undefined)}
                            options={[
                                { key: 'salary-pension', text: 'Løn/pension' },
                                { key: 'savings', text: 'Opsparing' },
                                { key: 'heritage-gift', text: 'Arv/gave' },
                                { key: 'lottery-games', text: 'Lotteri/spil' },
                                { key: 'interes-revenue', text: 'Renter/udbytte' },
                                { key: 'other', text: 'Andet' }
                            ]} />
                    </Stack.Item>
                    <Stack.Item>
                        <ChoiceGroup label='Hvorfor vil du gerne udlåne din formue?'
                            selectedKey={fundingReason}
                            onChange={(_event, option) => setFundingReason(option?.key || undefined)}
                            options={[
                                { key: 'optimse-saving-pension', text: 'Optimere opsparing/pension' },
                                { key: 'savings', text: 'Som gave til nærtstående part' },
                                { key: 'alternative-to-donations', text: 'Alternativ til velgørenhed' },
                                { key: 'trading', text: 'Trading' },
                                { key: 'other', text: 'Andet' }
                            ]} />
                    </Stack.Item>
                    <Stack.Item>
                        <ChoiceGroup label='Hvor meget planlægger du samlet at investere?'
                            selectedKey={fundingTargetPlan}
                            onChange={(_event, option) => setFundingTargetPlan(option?.key || undefined)}

                            options={[
                                { key: '750-150k', text: '750.000 - 1.500.00 DKK' },
                                { key: '150-500k', text: '1.500.000 - 5.000.00 DKK' },
                                { key: 'plus500k', text: '>5.000.000 DKK' },
                            ]} />
                    </Stack.Item>
                    <Stack.Item>
                        <ChoiceGroup label='Er du en politisk eksponeret person eller har du nær tilknytning til en sådan?'
                            selectedKey={peplist === true ? "yes" : peplist === false ? "no" : undefined}
                            onChange={(_event, option) => setPeplist(option?.key ? option.key === "yes" : undefined)}
                            options={[
                                { key: 'yes', text: 'Ja' },
                                { key: 'no', text: 'Nej' },
                            ]} />
                    </Stack.Item>
                </Stack>
            </Panel>
            <Dialog
                hidden={!isDialogVisible}
                onDismiss={() => setIsDialogVisible(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Er du sikker?',
                    subText: 'Du mangler at gemme dine ændringer'

                }}
                modalProps={{ isBlocking: true }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => { setIsDialogVisible(false); setOpenKYC(false) }} text="Ja" />
                    <DefaultButton onClick={() => setIsDialogVisible(false)} text="Annuller" />
                </DialogFooter>
            </Dialog>
        </Stack>
    )
}


const FileInput: React.FC<{ value?: string, setter: (val: string) => void, loading: boolean }> = ({ loading, value, setter }) => {
    const [succes, setSucess] = React.useState(!!value)
    const [uploading, setUploading] = React.useState(false)


    React.useEffect(() => {
        if (value) setSucess(true)
    }, [value])

    const fileRef = React.useRef<HTMLInputElement>(null)

    const handleClick = () => fileRef.current?.click()

    const handleUpload = async (fileList: FileList | null) => {
        if (!fileList) return
        setUploading(true)
        const uploadLink = await hooks.uploadFile(fileList[0])
        if (uploadLink?.key) {
            setSucess(true)
            setter(uploadLink.key)
        }
        setUploading(false)
    }


    return (
        <Stack.Item>{loading ? <Shimmer style={{ height: 32 }} /> : (
            <Stack horizontal verticalAlign='center'>
                <input ref={fileRef} type='file' style={{ display: 'none' }} onChange={(e) => handleUpload(e.target.files)} />
                {uploading ? (
                    <Spinner size={SpinnerSize.medium} style={{ height: 32 }} />
                ) : (
                    <><DefaultButton text='Upload' onClick={handleClick} />
                        {succes ? (
                            <>
                                <FontIcon iconName="SkypeCircleCheck" style={{ color: SharedColors.green10, paddingLeft: SmallGap, paddingRight: SmallGap }} />
                                <span>Fil uploaded</span>
                            </>
                        ) : (
                            <>
                                <FontIcon iconName="SkypeCircleMinus" style={{ color: SharedColors.red10, paddingLeft: SmallGap, paddingRight: SmallGap }} />
                                <span>Upload fil</span>
                            </>
                        )}
                    </>
                )}
            </Stack>
        )}</Stack.Item>
    )
}

const EditInvestor: React.FC<{ investor?: Investor, updateInvestor: (updated: Investor) => void }> = ({ investor, updateInvestor }) => {
    const [loading, setLoading] = React.useState(true)
    const [openKYC, setOpenKYC] = React.useState(false)

    const [phone, setPhone] = React.useState<string | undefined>()
    const [address, setAddress] = React.useState<string | undefined>()
    const [identitficationReference, setIdentitficationReference] = React.useState<string | undefined>()
    const [passportFileKey, setPassportFileKey] = React.useState<string | undefined>()
    const [addressDocumentationFileKey, setAddressDocumentationFileKey] = React.useState<string | undefined>()

    const handleUpdate = async () => {
        setLoading(true)
        const props: UpdateableInvestorProperties = { phone, address, identitficationReference, passportFileKey, addressDocumentationFileKey }
        const updated = await hooks.updateInvestor(props)
        if (updated) updateInvestor(updated)
        setLoading(false)
    }

    React.useEffect(() => {
        if (investor) {
            setLoading(false)
            setPhone(investor.phone)
            setAddress(investor.address)
            setIdentitficationReference(investor.identitficationReference)
            setPassportFileKey(investor.passportFileKey)
            setAddressDocumentationFileKey(investor.addressDocumentationFileKey)
        }
    }, [investor])


    return (
        <Stack style={{ minWidth: 300 }} tokens={{ childrenGap: SmallGap }}>
            <Stack.Item><h2>Velkommen</h2></Stack.Item>
            <Stack>
                <Stack.Item><span>Profilstatus</span></Stack.Item>
                <Stack.Item>{loading || !investor ? <Shimmer style={{ height: 18.5 }} /> : <ApprovedIcon approved={investor.approved}/>}</Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item><span>Navn</span></Stack.Item>
                <Stack.Item><b>{loading || !investor ? <Shimmer style={{ height: 18.5 }} /> : investor.name}</b></Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item><span>Email</span></Stack.Item>
                <Stack.Item><b>{loading || !investor ? <Shimmer style={{ height: 18.5 }} /> : investor.email}</b></Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item><span>Addresse</span></Stack.Item>
                <Stack.Item>{loading || !investor ? <Shimmer style={{ height: 32 }} /> : <TextField value={address} onChange={(_e, val) => setAddress(val)} />}</Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item><span>Telefonnummer</span></Stack.Item>
                <Stack.Item>{loading || !investor ? <Shimmer style={{ height: 32 }} /> : <TextField value={phone} onChange={(_e, val) => setPhone(val)} />}</Stack.Item>
            </Stack>

            <Stack>
                <Stack.Item><span>CPR Nummer</span></Stack.Item>
                <Stack.Item>{loading || !investor ? <Shimmer style={{ height: 32 }} /> : <TextField value={identitficationReference} onChange={(_e, val) => setIdentitficationReference(val)} />}</Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item><span>Pas/Kørekort</span></Stack.Item>
                <Stack.Item><FileInput setter={setPassportFileKey} loading={loading} value={passportFileKey} /></Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item><span>Addresse dokumentation (Sygesikring)</span></Stack.Item>
                <Stack.Item><FileInput setter={setAddressDocumentationFileKey} loading={loading} value={addressDocumentationFileKey} /></Stack.Item>
            </Stack>
            <PrimaryButton text='Gem' onClick={handleUpdate} />
            {loading || !investor ? <Shimmer style={{ height: 32 }} /> : <DefaultButton onClick={() => setOpenKYC(true)} >
                <FontIcon iconName={investor.pendingAprroval || investor.approved ? "CheckMark" : "Cancel"} style={{ marginRight: SmallGap }} />
                <span>{investor.approved? "KYC Godkendt": investor.pendingAprroval === undefined ? "Start KYC Process" : investor.pendingAprroval ? "KYC Data afsendt" : "Ikke godkendt"}</span>
            </DefaultButton>}
            {openKYC && <KYCDialog updateInvestor={updateInvestor} loading={loading} investor={investor} setOpenKYC={setOpenKYC} openKYC={openKYC}/>}
        </Stack>
    )

}

export default EditInvestor