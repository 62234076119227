

export const SmallGap = 12

export const LargeGap = 32

export const MarketingSiteUrl = "https://fbam-new-website.webflow.io/"


export const Colors = {
    black: "#101828",
    gray: "#475467",
    primary: "#b8ae90",
    light: "#f9fafb",
    lightest: "#ffffff"
}

export const ContractTemplates = [{
    templateId: "5991a321-2162-4f9f-a8b3-6cf816a6e023",
    name: "Test kontrakt"
}]