import * as React from 'react'
import { LargeGap, MarketingSiteUrl, SmallGap } from '../utils/globals'
import { ReactComponent as Logo } from '../assets/logo.svg';
import { DefaultButton, Link, NeutralColors, Stack } from '@fluentui/react';
import { IsMobile, IsTablet, useWindowSize } from '../utils/UIHooks';
import hooks from '../utils/hooks';

const CustomMenuLink: React.FC<{ title: string, link: string }> = ({ title, link }) => <Link href={link} target='_blank' underline={false} styles={{ root: { color: NeutralColors.gray190, fontSize: 16 } }}><b>{title}</b></Link>

const NavBar: React.FC = () => {
    const tablet = IsTablet(useWindowSize().width)

    const logout = async () => {
        await hooks.logout()
    }

    return (
        <Stack horizontal tokens={{ childrenGap: SmallGap }} verticalAlign='center' style={{ justifyContent: 'space-between', paddingBottom: SmallGap }}>
            <Stack horizontal tokens={{ childrenGap: LargeGap }} verticalAlign='center'>
                <Stack.Item>
                    <Logo style={{ width: 200 }} />
                </Stack.Item>
                {!tablet && (
                    <><Stack.Item>
                        <CustomMenuLink link={MarketingSiteUrl + 'fonde'} title='Fonde' />
                    </Stack.Item>
                        <Stack.Item>
                            <CustomMenuLink link={MarketingSiteUrl + 'about-us'} title='Om os' />
                        </Stack.Item>
                        <Stack.Item>
                            <CustomMenuLink link={MarketingSiteUrl + 'contact'} title='Kontakt' />
                        </Stack.Item>
                    </>)}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: LargeGap }} verticalAlign='center'>
                <Stack.Item>
                    <DefaultButton onClick={logout} text='Log ud' />
                </Stack.Item>
            </Stack>

        </Stack>
    )
}



export const Footer: React.FC = () => {
    const mobile = IsMobile(useWindowSize().width)


    return (
        <Stack horizontal tokens={{ childrenGap: SmallGap }} reversed={mobile} verticalAlign='center' style={{
            justifyContent: 'space-between', width: '100%',
            paddingLeft: SmallGap, paddingRight: SmallGap, paddingTop: LargeGap * 2, paddingBottom: LargeGap * 2
        }}>
            <Stack.Item>
                <Logo style={{ width: 200 }} />
            </Stack.Item>
            <Stack horizontal={!mobile} tokens={{ childrenGap: LargeGap }}>
                <Stack.Item>
                    <CustomMenuLink link={MarketingSiteUrl + 'fonde'} title='Fonde' />
                </Stack.Item>
                <Stack.Item>
                    <CustomMenuLink link={MarketingSiteUrl + 'about-us'} title='Om os' />
                </Stack.Item>
                <Stack.Item>
                    <CustomMenuLink link={MarketingSiteUrl + 'contact'} title='Kontakt' />
                </Stack.Item>
            </Stack>
        </Stack>
    )
}


export default NavBar