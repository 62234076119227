import { DashboardData, FileResponse, Investor, LoginAnswerChallangeRequest, LoginAnswerChallangeResponse, LoginChallangeRequest, LoginChallangeResponse, UpdateableInvestorProperties } from '@fbam/common';
import axios from 'axios';


export const endpoint = process.env.REACT_APP_API === "local" ? "http://localhost:3000/prod/" : "https://ifx75m6z4h.execute-api.eu-west-1.amazonaws.com/prod/"

export async function wrapper<T>(func: () => Promise<T & { error?: string }>): Promise<T | undefined> {
    try {
        const result = await func()
        if (result.error) { console.log(result.error); return undefined }
        return result
    } catch (error) {
        return undefined
    }
}


const getUser = async (): Promise<Investor | undefined> => await wrapper(async () => {
    const result = await axios.get<Investor>(endpoint + 'user', { withCredentials: true })
    return result.data
})

const startLogin = async (props: LoginChallangeRequest): Promise<LoginChallangeResponse | undefined> => await wrapper(async () => {
    const result = await axios.post<LoginChallangeResponse>(endpoint + 'autenticate', props, { withCredentials: true })
    return result.data
})

const confirmUser = async (props: LoginAnswerChallangeRequest): Promise<LoginAnswerChallangeResponse | undefined> => await wrapper(async () => {
    const result = await axios.post<LoginAnswerChallangeResponse>(endpoint + 'authentication/respond', props, { withCredentials: true })
    return result.data
})


const getDahboardData = async (): Promise<DashboardData | undefined> => await wrapper(async () => {
    const result = await axios.get<DashboardData>(endpoint + 'dashboard', { withCredentials: true })
    return result.data
})

const updateInvestor = async (props: UpdateableInvestorProperties): Promise<Investor | undefined> => await wrapper(async () => {
    const result = await axios.put<Investor>(endpoint + 'user', props, { withCredentials: true })
    return result.data
})


const uploadFile = async (file: File): Promise<FileResponse | undefined> => await wrapper(async () => {
    const result = await axios.get<FileResponse>(endpoint + 'file', { withCredentials: true })
    if (result) {
        await axios.put(result.data.uploadUrl, file, { headers: { 'Content-Type': 'image/png' }, withCredentials: false, maxBodyLength: Infinity })
    }
    return result.data

})


const logout = async (): Promise<boolean | undefined> => await wrapper(async () => {
    const result = await axios.get<boolean>(endpoint + 'logout', { withCredentials: true })
    window.location.reload()
    return result.data
})

const hooks = { getUser, confirmUser, startLogin, getDahboardData, uploadFile, updateInvestor, logout }

export default hooks