import { SharedColors } from "@fluentui/react";
import { SmallGap } from "../utils/globals";


export const ApprovedIcon: React.FC<{ approved: boolean, customFalseText?: string, customTrueText?: string }> = ({ approved, customFalseText, customTrueText }) => (
    <b style={{
        background: approved ? SharedColors.greenCyan10 : SharedColors.redOrange10,
        paddingLeft: SmallGap, paddingRight: SmallGap, borderRadius: SmallGap, color: "white", fontSize: 10, paddingBottom: 4, paddingTop: 4
    }}>
        {approved ? customTrueText || "Godkendt" : customFalseText || "Ikke godkendt"}
    </b>
)