import { Stack } from '@fluentui/react'
import * as React from 'react'
import { ReactComponent as Logo } from '../assets/logo.svg';

export const ErrorContext = React.createContext<{showErrorMessage(message: string): void }>({showErrorMessage: console.log});


export const Root: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [errorMessages, setErrorMessages] = React.useState<string[]>([])

    const showErrorMessage = (message: string) => {
        console.log(message)
        setErrorMessages((current) => [...current, message])
    }

    return (
        <ErrorContext.Provider value={{showErrorMessage}}>
            {errorMessages.map((i, key)=> <p key={key}>{i}</p>)}
            {children}
        </ErrorContext.Provider>
    )
}

export const LoginBody: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    return (
        <Root>
            <Stack horizontal>
                <Stack style={{
                    width: '100%', height: '100vh', backgroundColor: 'darkblue',
                    backgroundImage: "url(https://fbam-global-manually-uploaded-content.s3.eu-west-1.amazonaws.com/bg-image.jpg)", backgroundPosition: 'center', backgroundSize: 'cover'
                }}>

                </Stack>
                <Stack.Item style={{ minWidth: 300, paddingLeft: 30, paddingRight: 60, paddingTop: 60, paddingBottom: 60 }}>
                    <Stack horizontalAlign='center'>
                        <Logo style={{ width: 200, paddingBottom: 20 }} />
                    </Stack>
                    {children}
                </Stack.Item>
            </Stack>
        </Root>
    )
}

export const RootBody: React.FC<{ children: React.ReactNode }> = ({ children }) => {


    return (
        <Root>
            <Stack style={{ maxWidth: 1100, marginLeft: 'auto', marginRight: 'auto' }}>
                {children}
            </Stack>
        </Root>
    )
}

