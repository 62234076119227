import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ConfirmLogin from './pages/ConfirmLogin';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ThemeProvider, createTheme } from '@fluentui/react'
import AdminPage from './pages/AdminPage';
import FilePage from './pages/FilePage';
initializeIcons()

const fbamGoldTheme = createTheme({
  palette: {
    themePrimary: '#b8ae90',
    themeLighterAlt: '#fcfcfa',
    themeLighter: '#f4f1eb',
    themeLight: '#eae6da',
    themeTertiary: '#d4cdb8',
    themeSecondary: '#c0b79b',
    themeDarkAlt: '#a59c81',
    themeDark: '#8c846d',
    themeDarker: '#676150',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#919db6',
    neutralSecondary: '#74829f',
    neutralSecondaryAlt: '#74829f',
    neutralPrimaryAlt: '#5a6987',
    neutralPrimary: '#101828',
    neutralDark: '#2f3c58',
    black: '#1e2940',
    white: '#ffffff',
  },
  defaultFontStyle:  { fontFamily: 'Fbam' }
});


const Router: React.FC = () => (
  <Routes>
    <Fragment>
      <Route path='/' element={<Dashboard />} />
      <Route path='/login' element={<Login />} />
      <Route path='/confirmlogin/:sessionId' element={<ConfirmLogin />} />
      <Route path='/adminpage' element={<AdminPage />} />
      <Route path='/files/:fileId' element={<FilePage />} />
    </Fragment>
  </Routes>
)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider applyTo='body' theme={fbamGoldTheme}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>

  </React.StrictMode>
);

reportWebVitals();
